import React,{useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import HomeHeader from './HomeHeader'

function StartingPage() {
    return (

        <div>
  <div class="bg-StartingPage bg-cover relative overflow-hidden h-screen">

<HomeHeader></HomeHeader>

            <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-32 xl:py-40">
              <div class="lg:w-2/5 xl:w-3/5 flex flex-col items-start relative z-10">
              <span class="font-bold  text-black text-6xl sm:text-7xl ">
  RIG  <span className=" mt-2">Jr</span> 
</span>
                 <h1 class="text-gray-400 leading-tight mt-8">Welcome to RIG Jr, A digital Platform for
                    both learners and educators.<br/>Learn At Your
                    Own Pace
                 </h1>

                 <Link to='/class-registration' className=''>
                
                <button type="button" class="mt-4 py-2 px-14 bg-violet-400 text-white animate-pulse w-500 transition ease-in duration-200 text-center text-base font-semibold shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                         Register for Winter Course
                    </button>
                </Link>

                <div class="flex flex-row gap-2 mt-36">

                    <Link to='/loginStudents'>
                    <button type="button" class="py-2 px-12  bg-Our_purple_dark  text-black w-500px transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg text-white">
                        Learn
                    </button>
                    
                    </Link>



                    <Link to='/loginMentor'>
                    
                    <button type="button" class="py-2 px-12 bg-Our_purple_dark  text-black w-500px transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg text-white">
                         Teach
                    </button>
                    </Link>
                    
                </div>


               
                {/* <Link to='/scholarships'>
                
                <button type="button" class="mt-4 py-2 px-14 bg-white text-black w-500px transition ease-in duration-200 text-center text-base font-semibold shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                         Search For Scholarship
                    </button>
                </Link> */}
                    
                 </div>
                 <div class=" flex flex-col items-start ">
                 <div className=" h-full w-96">
                     <div className="h-auto w-auto ">
                        <img src={require("../assets/img/StartingPage_icons.png")} className="h-full w-full" />
                     </div>
                 </div>
                 </div>
                
            </div>
        </div>


        <div class="flex-wrap items-center justify-center gap-8  -mt-32 text-center sm:flex">
    <div class="w-full px-4 py-4 mt-6 bg-gray-50 rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 ">
        <div class="flex-shrink-0">
            <div class="flex items-center justify-center w-12 h-12 mx-auto text-white bg-indigo-500 rounded-md">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
            </div>
        </div>
        <h3 class="py-4 text-2xl font-semibold text-gray-700 sm:text-xl ">
            Coaching Class
        </h3>
        <p class="py-4 text-gray-500 text-md ">
            Solve your doubts and get ready for all your exams with our expert tutors.
        </p>
    </div>
    <div class="w-full px-4 py-4 mt-6 bg-gray-50 rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 sm:mt-16 md:mt-20 lg:mt-24 ">
        <div class="flex-shrink-0">
            <div class="flex items-center justify-center w-12 h-12 mx-auto text-white bg-indigo-500 rounded-md">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
            </div>
        </div>
        <h3 class="py-4 text-2xl font-semibold text-gray-700 sm:text-xl ">
            Dzongkha
        </h3>
        <p class="py-4 text-gray-500 text-md ">
            Take pride and learn Dzongkha from the finest dzongkha tutor.
        </p>
    </div>
    <div class="w-full px-4 py-4 mt-6 bg-gray-50  rounded-lg shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/4 ">
        <div class="flex-shrink-0">
            <div class="flex items-center justify-center w-12 h-12 mx-auto text-white bg-indigo-500 rounded-md">
                <svg width="20" height="20" fill="currentColor" class="w-6 h-6" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                    </path>
                </svg>
            </div>
        </div>
        <h3 class="py-4 text-2xl font-semibold text-gray-700 sm:text-xl ">
            Masterclass
        </h3>
        <p class="py-4 text-gray-500 text-md ">
            Let us help you level your skills in the.
        </p>
    </div>
</div>




<div  class="w-full md:p-8 p-7 bg-white">
    {/* <div class="flex flex-col md:flex-row md:items-end justify-between mb-12 header">
        <div class="title">
            <p class="mb-4 text-4xl font-bold text-gray-800">
                Explore Courses
            </p>
          
        </div>
        <div class="text-end">
            <form class="flex flex-col justify-center   w-full max-w-sm space-y-3 md:flex-row md:w-full md:space-x-3 md:space-y-0">
                <div class=" relative ">
                 
                      <input type="text" id="on-error-email" class="ring-purple-500 ring-2 rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" name="search" placeholder=" Search"

                      
                      />
        


<svg class="absolute text-purple-500 right-2 bottom-3 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
</svg>





                   
                    </div>
                 
                </form>
            </div>
        </div>

        <div class="md:px-6 md:py-6   md:rounded-lg md:bg-gray-50 md:shadow-lg">

        <div class="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4 place-items-center">



<div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
    <a href="#" class="block w-full h-full">
        <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
        <div class="w-full p-4 bg-white ">
            <p class="font-medium text-indigo-500 text-md">
                Article
            </p>
            <p class="mb-2 text-xl font-medium text-gray-800 ">
                Supercharged !
            </p>
            <p class="font-light text-gray-400  text-md">
                The new supercar is here, 543 cv and 140 000$. This is best racing GT about 7 years on...
            </p>
            <div class="flex items-center mt-4">
                <a href="#" class="relative block">
                    <img alt="profil" src="/images/person/6.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
                </a>
                <div class="flex flex-col justify-between ml-4 text-sm">
                    <p class="text-gray-800 ">
                        Jean Jacques
                    </p>
                    <p class="text-gray-400 ">
                        20 mars 2029 - 6 min read
                    </p>
                </div>
            </div>
        </div>
    </a>
</div>

<div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
    <a href="#" class="block w-full h-full">
        <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
        <div class="w-full p-4 bg-white ">
            <p class="font-medium text-indigo-500 text-md">
                Article
            </p>
            <p class="mb-2 text-xl font-medium text-gray-800 ">
                Supercharged !
            </p>
            <p class="font-light text-gray-400  text-md">
                The new supercar is here, 543 cv and 140 000$. This is best racing GT about 7 years on...
            </p>
            <div class="flex items-center mt-4">
                <a href="#" class="relative block">
                    <img alt="profil" src="/images/person/6.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
                </a>
                <div class="flex flex-col justify-between ml-4 text-sm">
                    <p class="text-gray-800 ">
                        Jean Jacques
                    </p>
                    <p class="text-gray-400 ">
                        20 mars 2029 - 6 min read
                    </p>
                </div>
            </div>
        </div>
    </a>
</div>

<div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
    <a href="#" class="block w-full h-full">
        <img alt="blog photo" src="/images/blog/1.jpg" class="object-cover w-full max-h-40"/>
        <div class="w-full p-4 bg-white ">
            <p class="font-medium text-indigo-500 text-md">
                Article
            </p>
            <p class="mb-2 text-xl font-medium text-gray-800 ">
                Supercharged !
            </p>
            <p class="font-light text-gray-400  text-md">
                The new supercar is here, 543 cv and 140 000$. This is best racing GT about 7 years on...
            </p>
            <div class="flex items-center mt-4">
                <a href="#" class="relative block">
                    <img alt="profil" src="/images/person/6.jpg" class="mx-auto object-cover rounded-full h-10 w-10 "/>
                </a>
                <div class="flex flex-col justify-between ml-4 text-sm">
                    <p class="text-gray-800 ">
                        Jean Jacques
                    </p>
                    <p class="text-gray-400 ">
                        20 mars 2029 - 6 min read
                    </p>
                </div>
            </div>
        </div>
    </a>
</div>



        </div>
     

         </div>

        */}
  



 <div class="relative p-4 mt-10 bg-white ">
     <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
         <div class="lg:col-start-2 md:pl-20">
             <h4 class="text-2xl font-extrabold leading-8 tracking-tight text-gray-900  sm:leading-9">
                 Learning at your fingertip
             </h4>
             <ul class="mt-10">
                 <li >
                     <div class="flex">
                         <div class="flex-shrink-0">
                             <div class="flex items-center justify-center w-12 h-12 text-white bg-violet-600 rounded-md">
                             <svg class="h-8 w-8 text-white"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" /></svg>
                             </div>
                         </div>
                         <div class="ml-4">
                             <h5 class="text-lg font-bold leading-6 text-gray-900 ">
                             Instant Access to tutors
                             </h5>
                             <p class="mt-2 text-base leading-6 text-gray-500 ">
                             Have 1:1 Chats with tutor anytime you have a doubts                            </p>
                         </div>
                     </div>
              </li>
                 <li class="mt-10">
                     <div class="flex">
                         <div class="flex-shrink-0">
                             <div class="flex items-center justify-center w-12 h-12 text-white bg-violet-600 rounded-md">
                             <svg class="h-8 w-8 text-white"  width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="5" y="11" width="14" height="10" rx="2" />  <circle cx="12" cy="16" r="1" />  <path d="M8 11v-4a4 4 0 0 1 8 0v4" /></svg>

                             </div>
                         </div>
                        <div class="ml-4">
                             <h5 class="text-lg font-bold leading-6 text-gray-900 ">
                             Learn from the Experts
                             </h5>
                             <p class="mt-2 text-base leading-6 text-gray-500 ">
                             Learn from field experts and gain real world knowledge.                            </p>
                       </div>
                     </div>
                 </li>
                 <li class="mt-10">
                     <div class="flex">
                         <div class="flex-shrink-0">
                             <div class="flex items-center justify-center w-12 h-12 text-white bg-violet-600 rounded-md">
                             <svg class="h-8 w-8 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"/>
 </svg>
                             </div>
                         </div>
                      <div class="ml-4">
                             <h5 class="text-lg font-bold leading-6 text-gray-900 ">
                                 Tailored Courses 
                             </h5>
                             <p class="mt-2 text-base leading-6 text-gray-500 ">
                               Custom made courses for your needs.
                             </p>
                         </div>
                     </div>
                 </li> 
             </ul>
         </div>
         <div class="relative m-14 -mx-4 mx-2  lg:col-start-1">
             <img src="https://img.freepik.com/free-vector/e-learning-interactions-illustration-concept_114360-23713.jpg?t=st=1724098034~exp=1724101634~hmac=b2a7f5889f704c478b205b3d76fcab88ea032439c81dae87af8233183d3b3204&w=1380" alt="illustration" class="relative w-auto mx-auto rounded "/>
         </div>
     </div>
 </div>


 </div>


        </div>
      
    )
}

export default StartingPage
{/* <div class="flex flex-col w-full md:w-2/3 bg-gray-200 h-screen"> */}