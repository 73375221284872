import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link, useNavigate} from 'react-router-dom'
import { UploadIcon } from '@heroicons/react/outline';

import Nav_men from "./Nav_men"


function CreateCourseForm() {

          const history = useNavigate()
          const [title,setTitle] = useState("")
          const [body,setBody] = useState("")
          const [url,setUrl] = useState([])
          const[progress,setProgress] = useState(false)
          const [subject,setSubject] = useState("")
          const [fee,setFee] = useState(0)
          const [category,setCategory] = useState("Video")



console.log(url)
  const PostData=()=>{

    fetch("/addcoursesessions",{
      method:"post",
      headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({

        title,
        body,
        fee,
        url,
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
      if(data.error){
          console.log("error")
      }
      else{
          history('/MentorDashboard')
      }
  })
  .catch(err=>{
      console.log(err)
  })

   }


//    const uploadppt=({target:{files}})=>{
//     setProgress(true)
   
//     let data = new FormData()

//     for(const file of files){

//    data.append('ppt', file)
//    data.append('name',file.name)
// }
//        fetch("/ppt", {

//          method:"post",
//          body: data
//      })
//      .then(res=>res.json())
//      .then(data=>{

//          for(const path of data.message){
//           setUrl(path.path)
//           console.log(path.path)
//          }

       
//      })
//      .catch(err=>{
//          console.log(err)
//      })

// }


   const uploadvid=({target:{files}})=>{

   
    let data = new FormData()

    for(const file of files){

   data.append('video', file)
   data.append('name',file.name)
}
       fetch("/video", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

         for(const path of data.message){
          setUrl(path.path)
          console.log(path.path)
         }

       
     })
     .catch(err=>{
         console.log(err)
     })

}

  
const uploadppt=({target:{files}})=>{

  setProgress(true)

 let data = new FormData()

 for(const file of files){

data.append('uploadimage', file)
data.append('name',file.name)
}
    fetch("/uploadimage", {

      method:"post",
      body: data
  })
  .then(res=>res.json())
  .then(data=>{

        setUrl(old=>[...old, data.url])
        setProgress(false)

   

    
  })
  .catch(err=>{
      console.log(err)
  })

}

   
const removePicture = (index,amt) => {
    
    
  setUrl([
    ...url.slice(0, index),
    ...url.slice(index + 1, url.length)
  ]);


  // setCreditItem([
  //   ...creditItem.slice(0, index),
  //   ...creditItem.slice(index + 1, creditItem.length)
  // ]);

  // setAmount(prevCount => prevCount - amt)

}




    return (
        <div>
          <Nav_men/>
              <div className="max-h-screen  flex flex-col justify-center bg-cover bg-back">
            <div className="max-w-lg w-full mx-auto mt-4 bg-white p-8 border-gray-300" >
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                 
                  <div className="col-span-6 ">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700 ">
                        Topic
                      </label>
                      <input
                        type="text"
                        value={title}
                        onChange={(e)=>setTitle(e.target.value)}
                     
                        className="appearance-none border border-gray-300 w-full py-2 px-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />
                    </div>
                    <div className="col-span-6 ">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700 ">
                        Description
                      </label>
                      <textarea
                        type="text"
                        value={body}
                        onChange={(e)=>setBody(e.target.value)}

                     
                        className="appearance-none border border-gray-300 w-full py-2 px-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />
                       {/* <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                        Subject
                      </label>
                      <select

                        value={subject}
                        onChange={(e)=>setSubject(e.target.value)}
                        id="Subject"
                        name="Subject"
                        
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="English">English</option>
                        <option value="Dzongkha" >Dzongkha</option>
                        <option value="Maths">Maths</option>
                        <option value="Biology" >Biology</option>
                        <option value="Chemistry">Chemistry</option>
                        <option vlaue="Physics">Physics</option>
                        <option vlaue="Geography">Geography</option>
                        <option value="History">History</option>
                        <option value="Arts">Arts</option>
                        <option value="Economics">Economics</option>
                        <option value="Computer Science">Computer Science</option>
                        <option value="Agriculture" >Agriculture</option>
                      </select>
                    </div> */}
                    </div>
                    <div className="col-span-6 ">
                      <label htmlFor="email_address" className="block text-sm font-medium text-gray-700 ">
                        Fee
                      </label>
                      <input
                        type="number"
                        value={fee}
                        onChange={(e)=>setFee(e.target.value)}
                        className="appearance-none border border-gray-300 w-full py-2 px-2 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                      />

                    </div>


                    <div className="col-span-4 w-full mb-5 ">
                        <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
         <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
             <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
         </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>



 

    
                               </div>          
    {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}


                  </div>

                  <div class="container flex flex-col items-center justify-center w-full mx-auto ">
    <ul class="grid  grid-cols-1">

      {url?url.map((item,index)=>{

        return( <li class="flex flex-row mb-2 ">
          <div class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                  <a href="#" class="relative block">
                      <img alt="profil" src={item} class="mx-auto object-cover rounded-sm h-48 w-48 "/>
                  </a>
             
             
            
              <button class="flex justify-end w-24 text-right"
              onClick={()=>{removePicture(index)}}
              >
              <svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="4" y1="7" x2="20" y2="7" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
              </button>
          </div>
      </li>)

      }):<></>}
       
    
      
    </ul>
</div>
                </div>
                <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

                     onClick={()=>PostData()}
                
                
                >
                    Create Course
                  </button>
                </div>
              </div>
            </div>
        </div>
        </div>
    )
}

export default CreateCourseForm
