
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
// import Header from '../components/Header';
import 'react-toastify/dist/ReactToastify.css';

import {UserContex} from '../App'
import Header from './Header';
import HomeHeader from './HomeHeader';
// import Footer from '../components/Footer';


const Class_Registration = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [otp,setOTP]= useState("")
  const [showotp,setShowOTP]= useState(false)
  const [progress,setProgress]= useState(false)
  const [sent,setSent]= useState(false)
  const[id,setId] = useState("")  
  const[url,setUrl] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[confirmpassword,setConfirmPassword]= useState("")
  const [isValidPassword,setisValidPassword]= useState(false)
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const [showprocess,setShowProcess]= useState(false)

  const PostData =()=>{

    setShowProcess(true)

    fetch("/register-class",{
      method:"post",
      headers:{
          "Content-Type":"application/json"
      },
      body: JSON.stringify({
        
          email:email,
          name,
          phone,
        
      })
      
  }).then(res=>res.json())
  .then(data=> {
     
      if(data.error){
        toast.error(data.error)


      }
      else{
        console.log("Login Successful")
        toast.success("Submitted Successfully")
        setShowProcess(false)
setSent(true)
        
        
      }
  })
  .catch(err=>{
      console.log(err)
  })

       }



       const Verify = ()=>{
        fetch("/sendotpuser",{
          method:"post",
          headers:{
              "Content-Type":"application/json"
          },
          body: JSON.stringify({
            
              email:email,
              
          })
          
      }).then(res=>res.json())
      .then(data=> {
         
          if(data.error){
  
              toast.error(data.error)
          }
          else{
              
              toast.success("Check Your Mail")
              setShowOTP(true)
          }
      })
      .catch(err=>{
          console.log(err)
      })
    
       }



       const uploadppt=({target:{files}})=>{

        setProgress(true)
    
        let data = new FormData()
    
        for(const file of files){
    
       data.append('uploadimage', file)
       data.append('name',file.name)
    }
    
    
    
           fetch("/uploadimage", {
    
             method:"post",
             body: data
         })
         .then(res=>res.json())
         .then(data=>{
    
              setUrl(data.url)
              setProgress(false)
    
          
    
           
         })
         .catch(err=>{
             console.log(err)
         })
    
    }
      


    return(


<>
<HomeHeader></HomeHeader>
  {/* component */}
  <style dangerouslySetInnerHTML={{__html: "@import url('https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css')" }} />
  <div className="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center px-5 py-5">
    <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full overflow-y-scroll" style={{maxWidth: 1000}}>
      <div className="md:flex w-full">
        <div className="hidden md:block w-1/2 bg-white py-10 px-10">

            <img className='h-full' src='https://img.freepik.com/free-vector/tablet-login-concept-illustration_114360-7963.jpg?size=626&ext=jpg&ga=GA1.1.16292083.1700716592&semt=sph'></img>
        </div>
        <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Register for Class </h1>
          </div>

          {sent?<>
          
          
<div class="bg-green-200 border-green-600 text-green-600 border-l-4 p-4" role="alert">
    <p class="font-bold">
        Success
    </p>
    <p>
        Congratulations, you have sent registration request. Our Team will get back to you with confirmation.
    </p>
</div>

          
          
          </>:<>
            <div>
          <div class="grid max-w-xl grid-cols-2 gap-4 m-auto">

       
<div className="py-1">
  <span className="px-1 text-sm text-gray-600">Name</span>
    <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
  bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
  
    placeholder="Name"
    value={name}
    onChange={(e)=>setName(e.target.value)}
    >

    </input>
  </div>

  <div className="py-1">
  <span className="px-1 text-sm text-gray-600">Phone</span>
    <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
  bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
  
    placeholder="Phone No."
    value={phone}
    onChange={(e)=>setPhone(e.target.value)}
    >

    </input>
  </div>
  </div>

            <div className="flex -mx-3">
              <div className="w-full px-3 mb-5">
                <label htmlFor className="text-md text-gray-600 font-semibold px-1">Email</label>
                <div className="flex">
                  <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-email-outline text-gray-400 text-lg" /></div>
                  <input type="email" required 
                  className="w-full -ml-10 pl-10 pr-3 py-2 placeholder-gray-400  rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
                  placeholder="youremail@example.com"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
       
      

          
                                        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}
   

{url?<img className=" w-28 h-28  rounded-lgflex items-center " src={url} ></img>:null
}
 
            <label class="flex items-center mb-3 space-x-3">
            <input type='checkbox' class="w-4 h-4">
            </input>
                                        <span class="font-normal text-gray-700 ">
                                            I agree to terms and contition 
                                        </span>
                                    </label> 


            {/* <label class="flex items-center mb-3 space-x-3">
                                    <input type="checkbox"  class="form-tick appearance-none bg-white bg-check h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-500 checked:border-transparent focus:outline-none"/>
                                        <span class="font-normal text-gray-700 ">
                                            I agree to terms and contition 
                                        </span>
                                    </label> */}
            <div className="w-full px-3 mb-5">
            <button className={`block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold cursor-pointer`}
                onClick={()=> PostData()}
                >Register </button>

               <ToastContainer/>

              </div>

    </div>
          </>}
    
</div>

        
          </div>
        </div>

        {
showotp? 
(
  <>
    <div
      className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
    >

        {/*content*/}
        <div className=" border-0 rounded-lg shadow-lg relative flex flex-col w-80 bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex rounded-full items-start  ">
            
            <button
              className="p-1 ml-auto rounded-full bg-white text-white text-3 "
              onClick={() => setShowOTP(false)}
            >
<svg class="h-8 w-8 text-red-500"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="12" cy="12" r="10" />  <line x1="15" y1="9" x2="9" y2="15" />  <line x1="9" y1="9" x2="15" y2="15" /></svg>

   </button>
          </div>
          {/*body*/}
         
          





    

      <div className="md:flex w-46">
        
        <div className="w-full     px-5 md:px-10 relative overflow-auto">



<div class="flex flex-col w-full max-w-md px-4 py-1 bg-white rounded-lg shadow  sm:px-6 md:px-8 lg:px-10">
         
         <div class="mt-1">
             


     

     <div className="py-1">


     <span className="px-1 text-sm text-gray-600"> Enter the OTP sent to {email}</span>
     <input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
     bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
     
     placeholder="OTP"
     value={otp}
     onChange={(e)=>setOTP(e.target.value)}
     >
     
     </input>
     </div>
     <button type="submit" class="
space-y-6 text-md block px-3 py-2 rounded-lg w-full bg-purple-500 hover:bg-purple-600 placeholder-gray-400  text-white "
onClick={()=>{PostData()}}
>
    Submit 
</button>
   
     
     </div>





        
                
             </div>
            


       
</div>


          </div>
       
        
        </div>



        

      </div>

      
    {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
  </>
)


:null

}


{
showprocess? 
(
  <>
    <div
      className="justify-center md:p-44 items-center flex   fixed inset-0 z-50 outline-none focus:outline-none overflow-auto"
    >

        {/*content*/}
        <div className=" border-0 relative flex flex-col w-80 bg-none outline-none focus:outline-none">
          {/*header*/}
          
          {/*body*/}
         
          





    

        

      <svg class="h-20 w-20 text-purple-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>


       
        
        </div>



        

      </div>

      
    {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
  </>
)
:null

}
      </div>

      {/* <Footer></Footer> */}

</>
  


    );
}

export default Class_Registration;

 

