import React, {useContext, useRef, useEffect, useState} from  'react';
import {Link, useNavigate} from 'react-router-dom'
// import DeleteIcon from '@mui/icons-material/Delete';
import DeleteIcon from '@mui/icons-material/Delete';
import {UserContex} from '../App'

import Nav from "./Nav"
import Footer from "./Footer"
import BookMarkedList from './BookMarkedList'
import Nav_men from './Nav_men';

const people = [
    {
      name: 'Jane Cooper',
      title: 'Regional Paradigm Technician',
      department: 'Optimization',
      role: 'Admin',
      email: 'jane.cooper@example.com',
      image:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60',
    },
  ]

  

function CourseSessionsTutor() {

  const [search,setSearch] = useState('')
  const [userDetails, setUserDetails] = useState("")
  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)


  console.log(userDetails)

  useEffect(()=>{

      fetch('/getMycourse',{
        headers:{
            Authorization: "Bearer " +localStorage.getItem("jwt")
            
        }
    }).then(res=>res.json())
    .then(result=>{
      
        setUserDetails(result.posts)
        
    })
  
  
    },[])


    const deletePost = (postId)=>{

        fetch(`/deletemycoursesubscription`,{
            method:"delete",
      headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
       sessionid: postId
        
      })
        }).then(res=>res.json())
        .then(result=>{
            const newData = userDetails.filter(item=>{
                return item._id !== result._id
            })
            setUserDetails(newData)
        }).catch(err=>{
            console.log(err)
        })
    }
    const StartMeeting = (postId,topic)=>{

        fetch(`/createMeeting`,{
            method:"post",
      headers:{
          "Content-Type":"application/json",
          Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        postId: postId,
        topic:topic
        
      })
        }).then(res=>res.json())
        .then(result=>{
            

            window.location.href= result.start_url


        }).catch(err=>{
            console.log(err)
        })
    }
  
    return (
        <div>
         {/* Nav */}
        <Nav_men/>
        {/* end Nav */}
        <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900 text-center">Your  Courses</h1>
        </div>
      </header>

      {
        userDetails?<main >
      <div className="grid md:grid-cols-4 grid-cols-1 items-center w-full bg-gray-100 p-6">
      
      {userDetails.map(item=>{

          return(
            <>
        



        

        <Link to={`/singlecoursedash/${item._id}`}>
       
  
<div class="m-auto overflow-hidden rounded-lg shadow-lg cursor-pointer h-90 w-60 md:w-80">
    <a href="#" class="block w-full h-full">
        <img alt="blog photo" src={item.url?item.url[0]:""} class="object-cover w-full max-h-40"/>
        <div class="w-full p-4 bg-white dark:bg-gray-800">
            <p class="font-medium text-indigo-500 text-md">
            </p>
            <p class="mb-2 text-xl font-medium text-gray-800 dark:text-white">
                {item.title}
            </p>
            <p class="font-light text-gray-400 dark:text-gray-300 text-md">
                {item.body}
            </p>
        </div>
    </a>
</div>

    </Link>
 

    


                    
                    </>
          )


      })}
        



      
         

           
          
           
       
      </div>

      </main>:<h1>Loading...</h1>


      }
      
        {/* Footer */}
        <Footer/>
        {/* end Footer */}    
        </div>
    )
}

export default CourseSessionsTutor

  
